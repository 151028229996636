import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LocaisService } from 'app/main/content/pastoral/estrutura/locais/locais.service';
import { DataService } from 'app/main/shared/services/data.service';
import { NGXLogger } from 'ngx-logger';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FiltersService } from '../../services/filters.service';
import { GlobalService } from './../../services/global.service';
import { LocaisSelectComponentService } from './locais-select-component.service';

@Component({
  selector: 'app-locais-select-component',
  templateUrl: './locais-select-component.component.html',
  styleUrls: ['./locais-select-component.component.scss'],
})
export class LocaisSelectComponentComponent implements OnInit {
  locais: any = [];
  public locaisFilter: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public LOCALFILTER: FormControl = new FormControl();

  private _onDestroy = new Subject<void>();

  form: FormGroup;

  private tipo;

  public subscribersgetLocaisCount = 0;
  public number: number;

  @Output() valueChanges = new EventEmitter();

  private dataLoaded = false;

  constructor(
    private logger: NGXLogger,
    private formBuilder: FormBuilder,
    private nav: LocaisSelectComponentService,
    private global: GlobalService,
    private data: DataService,
    private filters: FiltersService,
    private locaisService: LocaisService
  ) {
    // this.getLocais();

    this.LOCALFILTER.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterIgrejas();
    });

    this.locaisService
      .getTipo()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        data => {
          this.tipo = data.data[0].TIPO;
        },
        error => {
          console.log(1, 'Erro ao buscar locais', error);
        }
      );

    this.subscribersgetLocaisCount = this.locaisService.getSubscribersgetLocaisCount();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      LOCAL: [''],
    });

    this.form
      .get('LOCAL')
      .valueChanges.pipe(takeUntil(this._onDestroy))
      .subscribe(data => {
        this.nav.setLocalSelected(data);
        this.emit();
      });

      if (!this.dataLoaded) {
        this.getLocais();
      }
  }

  getLocais() {
    console.log('Buscando locais...........');
    this.locaisService
      .getLocaisByPermissao()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(data => {
        this.locais = data;
        this.locaisFilter.next(this.locais.slice());
      },
      error => {
        console.log(2, 'Erro ao buscar locais', error);
      });
  }

  private filterIgrejas() {
    this.filters.genericFilter(this.locais, this.LOCALFILTER, this.locaisFilter, 'NOME');
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  emit() {
    this.valueChanges.emit();
    this.getLocais();
  }
}
