import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostBinding, Inject, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { printSubscribers, track } from 'observable-profiler';
import { Subscription } from 'rxjs';

import { DataService } from './shared/services/data.service';

@Component({
  selector: 'fuse-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FuseMainComponent implements OnDestroy {
  onConfigChanged: Subscription;
  fuseSettings: any;
  navigation: any;

  @HostBinding('attr.fuse-layout-mode') layoutMode;

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private fuseConfig: FuseConfigService,
    private platform: Platform,
    private data: DataService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.onConfigChanged = this.fuseConfig.onConfigChanged.subscribe(newSettings => {
      this.fuseSettings = newSettings;
      this.layoutMode = this.fuseSettings.layout.mode;
    });

    if (this.platform.ANDROID || this.platform.IOS) {
      this.document.body.className += ' is-mobile';
    }

    console.log("Versão Carlos");
    
    // this.geraMenu();
  }

  ngOnDestroy() {
    this.onConfigChanged.unsubscribe();
  }

  addClass(className: string) {
    this._renderer.addClass(this._elementRef.nativeElement, className);
  }

  removeClass(className: string) {
    this._renderer.removeClass(this._elementRef.nativeElement, className);
  }

  onActivate() {
    track();
  }

  onDeactivate(component: object) {
    const subscribers = track(false);
    printSubscribers({
      subscribers,
      prefix: component.constructor.name,
      timeout: 200,
    });
  }
}
