import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'app/main/shared/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class MovimentacoesService {

  constructor(
    public http: HttpClient,
    public global: GlobalService  ) {

  }

  getHeaders() {
    let headers = new HttpHeaders({
      'authorization': this.global.getToken()
    });
    return headers;
  }

  realizarMovimentacao(obj) {
    return this.http.post<any>(this.global.url + '/movimentacoes/realizarMovimentacao', obj, { headers: this.getHeaders() });
  };

  apagarMovimentacao(obj) {
    return this.http.put<any>(this.global.url + '/movimentacoes/apagarMovimentacao', obj, { headers: this.getHeaders() });
  };

  atualizarMovimentacao(obj) {
    return this.http.put<any>(this.global.url + '/movimentacoes/atualizarMovimentacao', obj, { headers: this.getHeaders() });
  };

  getMovimentacoes(obj) {
    return this.http.post<any>(this.global.url + '/movimentacoes/getMovimentacoes', obj, { headers: this.getHeaders() });
  };

  getMovimentacoesByLancamentoAndReceitaOrDespesa(obj) {
    return this.http.post<any>(this.global.url + '/movimentacoes/getMovimentacoesByLancamentoAndReceitaOrDespesa', obj, { headers: this.getHeaders() });
  };

  bancos(local) {
    let obj = {local: local}
    return this.http.post<any>(this.global.url + '/relatorios/bancos', obj, { headers: this.getHeaders() });
  };
}
