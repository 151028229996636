import { Component, OnInit, Inject } from '@angular/core';

import {
    MAT_DIALOG_DATA,
    MatDialogRef
} from '@angular/material';

@Component({    
    selector: 'app-modal-item',
    templateUrl: './modalItem.component.html',
    styleUrls: ['./modalItem.component.scss']
})

export class ModalItem implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ModalItem>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    { }

    item;

    ngOnInit() {
        this.item = this.data.item;   
    }

    cancelar() {
        this.dialogRef.close();
        this.item = null;
    }
}