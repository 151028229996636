import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';

import { navigation } from '../../navigation/navigation';

import { MatDialog, MatDialogRef } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ReplaySubject } from 'rxjs';
import { DataService } from '../shared/services/data.service';
import { GlobalService } from '../shared/services/global.service';
import { FuseContactsContactFormDialogComponent } from './contact-form/contact-form.component';

@Component({
  selector: 'fuse-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class FuseToolbarComponent {
  userStatusOptions: any[];
  languages: any;
  selectedLanguage: any;
  showLoadingBar: boolean;
  horizontalNav: boolean;
  noNav: boolean;
  navigation: any = [];

  dialogRef: any;
  igrejas: any = [];
  tb = 'G009';

  public igrejasFilter: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public IDG004FILTER: FormControl = new FormControl();

  user;

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  private menuSubscription;

  constructor(
    private router: Router,
    private fuseConfig: FuseConfigService,
    private sidebarService: FuseSidebarService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public global: GlobalService,
    private data: DataService
  ) {
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us',
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr',
      },
    ];

    this.selectedLanguage = this.languages[0];
    this.getIgrejas();

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.showLoadingBar = true;
      }
      if (event instanceof NavigationEnd) {
        this.showLoadingBar = false;
      }
    });

    this.fuseConfig.onConfigChanged.subscribe(settings => {
      this.horizontalNav = settings.layout.navigation === 'top';
      this.noNav = settings.layout.navigation === 'none';
    });

    this.user = this.global.getUser();

    if (this.user) {
      this.geraMenu(this.user.IDG031);
    }
  }

  toggleSidebarOpened(key) {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  search(value) {
    // Do your search here...
  }

  setLanguage(lang) {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this.translate.use(lang.id);
  }

  editContact(contact) {
    this.dialogRef = this.dialog.open(FuseContactsContactFormDialogComponent, {
      panelClass: 'contact-form-dialog',
      height: 'auto',
      data: {
        contact: contact,
        action: 'edit',
      },
    });

    this.dialogRef.afterClosed().subscribe(response => {
      if (!response) {
        return;
      }
      const actionType: string = response[0];
      const formData: FormGroup = response[1];
      switch (actionType) {
        /**
         * Save
         */
        case 'save':
          // this.contactsService.updateContact(formData.getRawValue());

          break;
        /**
         * Delete
         */
        case 'delete':
          // this.deleteContact(contact);

          break;
      }
    });
  }

  geraMenu(id) {
    if (id) {
      //RETORNA OS ITENS PERMITIDO PARA O PERFIL
      this.menuSubscription = this.data
        .list(
          `G033 P,G032 M/M.ORDEM/ASC/P.IDG032=M.IDG032,P.IDG031=${id},M.DELETED=N,M.STS=A`
        )
        .subscribe(
          data => {
            let menu = data.data;
            // data.data;

            console.log(':geraMenu:MENU :geraMenu:', data);

            let children: any = [];

            // GERA O MENU
            for (let item of menu) {
              if (item.type == 1) {
                item.type = 'collapse';
              } else {
                item.type = 'item';
              }

              // VERIFICA SE O ITEM É PAI
              if (!item.idpai) {
                //DELETA A PROPRIEDADE URL
                if (!item.url) {
                  delete item.url;
                }

                //VERIFICA SE O PAI POSSUI FILHOS
                children = menu.filter(c => {
                  return c.idpai == item.IDG032;
                });

                // SE TIVER OS children SÃO INSERIDOS
                if (children.length > 0) {
                  item.children = children;
                }

                navigation.push(item);
              }
            }
            this.navigation = navigation;
          },
          error => {}
        );
    }
  }

  sair() {
    // reseta o menu
    this.navigation.length = 0;

    this.global.clear();
    this.router.navigate(['/conta/entrar']);
  }

  getIgrejas() {
    this.data.list(`G004/IDG004/desc/DELETED=N,STS=A`).subscribe(
      data => {
        this.igrejas = data.data;
        this.igrejasFilter.next(this.igrejas.slice());
      },
      error => {}
    );
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }
  }
}
