import { Injectable, ElementRef } from "@angular/core";
import * as moment from "moment";
import { FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";


declare var jsPDF: any; // Important

import swal from 'sweetalert';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: "root"
})
export class UtilsService {
    hoje;

    constructor(
        private snackBar: MatSnackBar
        
    ) {
        this.hoje = this.formatDate(new Date());
    }

    // ------------- DATA ---------------
    returnHoje() {
        return this.hoje;
    }

    formatDate(date: Date) {
        if (date != null) {
            return moment(date).format("YYYY-MM-DD");
        }
        return null;
    }

    

    formatDateTime(date: Date) {
        if (date != null) {
            return moment(date).format("YYYY-MM-DD HH:MM:ss");
        }
        return null;
    }

    formataStringData(data) {
        if (data != null) {
            var dia = data.split("/")[0];
            var mes = data.split("/")[1];
            var ano = data.split("/")[2];

            return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
            // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
        }
        return null;
    }

    retornaDescricaoMes(mes){

        let descMes;

        switch(mes){
            case '1':
                descMes = 'JAN';
                break;
            case '2':
                descMes = 'FEV';
                break;
            case '3':
                descMes = 'MAR';
                break;
            case '4':
                descMes = 'ABR';
                break;
            case '5':
                descMes = 'MAI';
                break;
            case '6':
                descMes = 'JUN';
                break;
            case '7':
                descMes = 'JUL';
                break;
            case '8':
                descMes = 'AGO';
                break;
            case '9':
                descMes = 'SET';
                break;
            case '10':
                descMes = 'OUT';
                break;
            case '11':
                descMes = 'NOV';
                break;
            case '12':
                descMes = 'DEZ';
                break;
            default:
                descMes = '-';
                break;
        }
        return descMes;
    }

    entradaSaida(n){
        switch(n){
            case 'E':
                n = "Entrada";
                break;
            case 'S':
                n = "Saída";
                break;
        }
        return n;
    }

    formataDataBr(data){
        if (data != null) {
            var dia = data.substr(8,2);
            var mes = data.substr(5,2);
            var ano = data.substr(0,4);

            return ("0" + dia).slice(-2) + '-' + ("0" + mes).slice(-2) + '-' + ano;
        }
        return null;
    }

    formataDinheiro(n, notPrefix?) {
        let retorno = '';
        if (n == null || n == undefined || n == 'null') {
          retorno = " ";
        } else {
          n = parseFloat(n);
          if (!notPrefix){
            retorno = "R$ " + n.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
          }
          else {
            retorno = n.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
          }
        }
        return retorno;
    }

    formatTimeZone(campo: string, array: any[]) {
        for (let i = 0; i < array.length; i++) {
            if (array[i][campo] != null && array[i][campo] != "") {
                array[i][campo] = moment(array[i][campo])
                    .add(5, "hours")
                    .format("YYYY-MM-DD");
            }
        }

        return array;
    }

    formatTimeZoneUnique(date) {
        date = moment(date)
            .add(3, "hours")
            .toISOString();

        return date;
    }

    // ------------- DISABLE AND ENABLE ---------------
    setDisable(form: FormGroup, except?: string[]) {
        let campos = Object.keys(form.value);

        let tamanho = campos.length;
        let i = 0;
        for (i; i < tamanho; i++) {
            if (except != null && except.indexOf(campos[i]) != -1){
                continue;
            }
            form.get(campos[i]).disable();
        }
    }

    setEnable(form: FormGroup, except?: string[]) {
        let campos = Object.keys(form.value);
        let tamanho = campos.length;
        let i = 0;
        for (i; i < tamanho; i++) {
            if (except != null && except.indexOf(campos[i]) != -1){
                continue;
            }
            form.get(campos[i]).enable();
        }        
    }

    // ------------- EXPORT  ---------------
    exportAsExcel(titulo: string, colunaOcultar: string, tableexport: ElementRef, foto?: number, status?, iconAddRemove?) {
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
            tableexport.nativeElement,
            { display: true }
        ); //converts a DOM TABLE element to a worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        
        let chaves = Object.keys(ws);
       
        let chavesAcoes = chaves.filter(function is(value) {
            return value.substring(0, 1) == colunaOcultar; //COLUNA DE AÇÕES
        });

        let chavesStatus = chaves.filter(function is(value) {
            return value.substring(0, 1) == "A";
        });

       
        let chavesIcon = [];
        if (iconAddRemove){
            chavesIcon = chaves.filter(function is(value) {
                return value.substring(0, 1) == iconAddRemove;
            });
        }
        
        
        if (!status) {
            
            for (let i = 0; i < chavesAcoes.length; i++) {
                
                delete ws[chavesAcoes[i]]; //DELETA COLUNA DE AÇÕES
                
                //SUBSTITUI ICONE POR STRING
                if (i != 0) {
                    if (ws[chavesStatus[i]].v == "lens") {
                        ws[chavesStatus[i]].v = "DESATIVADO";
                    } else if (ws[chavesStatus[i]].v == "brightness_1") {
                        ws[chavesStatus[i]].v = "ATIVADO";
                    }
                }
                if (i == 0 && !ws[chavesStatus[i]].v ){
                    ws[chavesStatus[i]].v = "STATUS"; //ALTERA NOMECLATURA DA COLUNA STATUS
                    ws[chavesStatus[i]].t = "s";
                }
            }

            for (let i = 0; i < chavesIcon.length; i++){                
                //SUBSTITUI ICONE POR STRING
                if (i != 0) {
                    if (ws[chavesIcon[i]].v == "add") {
                        ws[chavesIcon[i]].v = "ENTRADA";
                    } else if (ws[chavesIcon[i]].v == "remove") {
                        ws[chavesIcon[i]].v = "SAÍDA";
                    }
                }

                if (i == 0){
                    ws[chavesIcon[i]].v = "TIPO"; 
                    ws[chavesIcon[i]].t = "s";
                }
            }
        }

        if (foto) {
            ws["!cols"] = [];
            ws["!cols"][foto] = { hidden: true };
        }

        /* save to file */
        XLSX.writeFile(wb, `${titulo}.xlsx`);
    }

    exportAsExcelLancamento(titulo: string, colunaOcultar: string, colunaSelect: string, colunaStatus: string, tableexport: ElementRef, foto?: number, status?, iconAddRemove?) {
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
            tableexport.nativeElement,
            { display: true }
        ); //converts a DOM TABLE element to a worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        
        let chaves = Object.keys(ws);
       
        let chavesAcoes = chaves.filter(function is(value) {
            return value.substring(0, 1) == colunaOcultar; //COLUNA DE AÇÕES
        });

        let selects = chaves.filter(function is(value) {
            return value.substring(0, 1) == colunaSelect; //COLUNA DE SELECT
        });

        let chavesStatus = chaves.filter(function is(value) {
            return value.substring(0, 1) == colunaStatus;
        });

       
        let chavesIcon = [];
        if (iconAddRemove){
            chavesIcon = chaves.filter(function is(value) {
                return value.substring(0, 1) == 'A';
            });
        }
        
        if (!status) {
            
            for (let i = 0; i < chavesAcoes.length; i++) {
                
                delete ws[chavesAcoes[i]]; //DELETA COLUNA DE AÇÕES
                

                //SUBSTITUI ICONE POR STRING
                if (i != 0) {
                    if (ws[chavesStatus[i]].v == "lens") {
                        ws[chavesStatus[i]].v = "ABERTO";
                    } else if (ws[chavesStatus[i]].v == "brightness_1") {
                        ws[chavesStatus[i]].v = "BAIXADO";
                    }
                    else{
                        ws[chavesStatus[i]].v = "PARCIALMENTE BAIXADO";
                    }
                }
                if (i == 0 && !ws[chavesStatus[i]].v ){
                    ws[chavesStatus[i]].v = "STATUS"; //ALTERA NOMECLATURA DA COLUNA STATUS
                    ws[chavesStatus[i]].t = "s";
                }
            }

            for (let i = 0; i < chavesIcon.length; i++){                
                //SUBSTITUI ICONE POR STRING
                if (i != 0) {
                    if (ws[chavesIcon[i]].v == "add") {
                        ws[chavesIcon[i]].v = "ENTRADA";
                    } else if (ws[chavesIcon[i]].v == "remove") {
                        ws[chavesIcon[i]].v = "SAÍDA";
                    }
                }

                if (i == 0){
                    ws[chavesIcon[i]].v = "TIPO"; 
                    ws[chavesIcon[i]].t = "s";
                }
            }
        }

        if (foto) {
            ws["!cols"] = [];
            ws["!cols"][foto] = { hidden: true };
        }

        /* save to file */
        XLSX.writeFile(wb, `${titulo}.xlsx`);
    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    // ------------- TRANSLATE PAGINATOR ---------------
    paginatorTranslate(paginador) {
        paginador._intl.itemsPerPageLabel = "Itens por página";
        paginador._intl.nextPageLabel = "Próxima página";
        paginador._intl.previousPageLabel = "Página anterior";
        paginador._intl.getRangeLabel = (
            page: number,
            pageSize: number,
            length: number
        ) => {
            if (length == 0 || pageSize == 0) {
                return `0 de ${length}`;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            const endIndex =
                startIndex < length
                    ? Math.min(startIndex + pageSize, length)
                    : startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} de ${length}`;
        };
    }

    paginatorTranslateBack(paginador, infoPage) {
        paginador._intl.itemsPerPageLabel = "Itens por página";
        paginador._intl.nextPageLabel = "Próxima página";
        paginador._intl.previousPageLabel = "Página anterior";
        paginador._intl.getRangeLabel = (
            page: number,
            pageSize: number,
            length: number
        ) => {
            if (infoPage.ttLinhas == 0) {
                return `0 de ${length}`;
            }
            length = infoPage.ttLinhas;
            const startIndex = infoPage.linhaInicial + 1;
            const endIndex = startIndex + infoPage.itensPagina;
            return `${startIndex + 1} - ${endIndex} de ${length}`;
        };
    }

    removerCUD(array) {
        if (!array) {
            return;
        } else {
            for (let i = 0; i < array.length; i++) {
                delete array[i].CREATED;
                delete array[i].UPDATED;
                delete array[i].DELETED;
            }
            return array;
        }
    }


    // ------------- TRIM AND UPPERCASE ---------------
    trimAndUpperCase(form: FormGroup) { //trim and uppercase
        let keys = Object.keys(form.controls);

        for (let i = 0; i < keys.length; i++) {
            if (form.get(keys[i]) != null &&
                form.get(keys[i]).value != null &&
                form.get(keys[i]).value != '' &&
                typeof form.get(keys[i]).value == "string") {
                    form.get(keys[i]).setValue(form.get(keys[i]).value.trim().toUpperCase());
            }
        }

        return form;
    }

    // ------------- COMPARATORS ---------------
    compara2Datas(form: FormGroup, final: string, inicial: string) {
        if (form.get(final).value != null && form.get(inicial).value != null) {
            if (form.get(final).value > form.get(inicial).value) {
                form.get(final).setErrors(null);
                form.get(inicial).setErrors(null);
            }
            else {
                form.get(final).setErrors({ 'menor': true });
                form.get(inicial).setErrors({ 'maior': true });
            }
        }
        else {
            form.get(final).setErrors(null);
            form.get(inicial).setErrors(null);
        }

        return form;
    }

    comparaDataDeHoje(form: FormGroup, campo: string) {
        if (form.get(campo).value != null) {
            if (form.get(campo).value <= this.hoje) {
                form.get(campo).setErrors(null);
            }
            else {
                form.get(campo).setErrors({ 'maior': true });
            }
        }
        else {
            form.get(campo).setErrors(null);
        }
        return form;
    }

    comparaDataDeHojeMenor(form: FormGroup, campo: string) {
        if (form.get(campo).value != null) {
            if (form.get(campo).value >= this.hoje) {
                form.get(campo).setErrors(null);
            }
            else {
                form.get(campo).setErrors({ 'menor': true });
            }
        }
        else {
            form.get(campo).setErrors(null);
        }
        return form;
    }

    // ------------- ALERTS ---------------
    alertMaxLenght(max: number) {
        this.snackBar.open('O campo se limita a ' + max + ' caracteres', '', {
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'yellow-snackbar'
        });
    }

    getSnack (color: string, txt) {
        return this.snackBar.open(txt, '', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: `${color}-snackbar`
        });
    }

    statusSuccess() {
        this.snackBar.open('Status atualizado com sucesso!', '', {
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'green-snackbar'
        });
    }

    statusError() {
        this.snackBar.open('Erro ao atualizar status!', '', {
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'red-snackbar'
        });
    }

    confirmRemove(txt?, msg?){
        return swal({
            title: txt ? txt : "Confirma a exclusão?",
            text: msg ? msg : "Uma vez deletado, não será possível desfazer a ação.",
            icon: "warning",
            buttons: ["Cancelar", true],
            dangerMode: true,
        })
    }

    msgError(txt: string, duracao?) {
        duracao = duracao ? duracao : 2000;
        
        this.snackBar.open(txt, '', {
            duration: duracao,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'red-snackbar'
        });
    }

    msgSuccess(txt: string, duration?) {
        this.snackBar.open(txt, '', {
            duration: duration ? duration : 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'green-snackbar'
        });
    }

    msgAlert(txt: string) {
        this.snackBar.open(txt, '', {
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'yellow-snackbar'
        });
    }


    listFiltros(filtros, dados) {
        
        let filter = [];
        let operador = filtros[0].OPERADOR;
        let expressao = filtros[0].EXPRESSAO;
        let campoReal = filtros[0].CAMPOREAL;
       
        if (operador === "IGUAL A") {
            filter = dados.filter(d => { return d[campoReal] === expressao });
        }
    
        if(operador === "MAIOR QUE"){
            filter = dados.filter(d => { return d[campoReal] > expressao });
        }
    
        if(operador === "MENOR QUE"){
            filter = dados.filter(d => { return d[campoReal] < expressao });
        }
    
        if(operador === "MENOR OU IGUAL QUE"){
            filter = dados.filter(d => { return d[campoReal] <= expressao });
        }
    
        if(operador === "MAIOR OU IGUAL A"){
            filter = dados.filter(d => { return d[campoReal] >= expressao });
        }
    
        if(operador === "DIFERENTE DE"){
            filter = dados.filter(d => { return d[campoReal] != expressao });
        }
        
        if(operador === "CONTÉM A EXPRESSÃO"){
            filter = dados.filter(d => { return d[campoReal].includes(expressao) });
        }
    
        if(operador === "NÃO CONTÉM A EXPRESSÃO"){
            filter = dados.filter(d => { return d[campoReal].includes(expressao) != true });
        }
    
        if(operador === "DIFERENTE DE VAZIO"){
            filter = dados.filter(d => { return d[campoReal] != ""  });
        }
    
        return filter;
    
    }


    getTypes(tipo){
        switch(tipo){
            case "TODOS":
            case "REGIONAL":
                return [
                    {
                        value: 'REGIONAL',
                        name: 'REGIONAL'
                    },
                    {
                        value: 'DISTRITAL',
                        name: 'DISTRITAL'
                    },
                    {
                        value: 'LOCAL',
                        name: 'LOCAL'
                    },
                ]
            case "DISTRITAL":
                    return [
                        {
                            value: 'DISTRITAL',
                            name: 'DISTRITAL'
                        },
                        {
                            value: 'LOCAL',
                            name: 'LOCAL'
                        },
                    ]
            case "LOCAL":
                    return [
                        {
                            value: 'LOCAL',
                            name: 'LOCAL'
                        },
                    ]
        }
             

    }
   

    
}
