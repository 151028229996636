import { Injectable, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { async } from 'q';
declare var jsPDF: any; // Important
import { FileSaverService } from 'ngx-filesaver';

@Injectable()
export class RelatoriosService {
  constructor(private http: HttpClient, private _FileSaverService: FileSaverService) {}

  /*************************************************************************************************************************************
   PDF
  *************************************************************************************************************************************/

  // -----------------  RELATÓRIO BALANCETES ANUAL

  exportBalancetes(
    titulo_arquivo: string,
    titulo_grid_1: string,
    titulo_grid_2: string,
    columns,
    rowsDespesas,
    rowsReceitas,
    rowsSaldos,
    columnsSaldos,
    user,
    pastor,
    tesoureiro,
    totalRows,
    periodoRelatorio
  ) {
    this.http.get('/assets/images/bg_relatorio.jpg', { responseType: 'blob' }).subscribe(data => {
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = function () {
        executa(reader.result);
      };
    });

    function executa(bgRelatorio) {
      var doc = new jsPDF('p', 'pt', 'a4');
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var totalPage;
      var titulo = titulo_grid_1 + ' - RECEITAS';
      var campos = [
        'SUB-TOTAL',
        'TOTAL RECEITAS',
        'SALDO INICIAL CAIXA/BANCOS/APLICACOES',
        'SOMA: RECEITAS + SALDO ANTERIOR',
        'TOTAL DESPESAS',
        'SALDO DO CAIXA FINAL',
        'SALDO EM BANCOS FINAL',
        'SALDO EM CAIXA + SALDO EM BANCOS',
        'DESPESAS + SALDO ATUAL',
        'TOTAL DE SALDOS',
        'SALDO MÊS ANTERIOR',
      ];

      //Paginação
      doc.page = 1;

      function footer() {
        doc.setFontSize(8);
        doc.setTextColor('#000');
        doc.text(170, 830, `Usuário - ${user}`);
        doc.text(420, 830, moment().format('DD/MM/YYYY - HH:mm'));
        doc.text(570, 830, '' + doc.page);
        totalPage = doc.page++;
      }

      doc.autoTable(columns, rowsReceitas, {
        theme: 'striped',
        margin: { top: 90, left: 20, right: 20 },
        styles: { halign: 'left' },
        headStyles: {
          fillColor: [66, 66, 66],
        },
        didDrawPage: function (data) {
          // CONFIG TITULOS
          doc.setFontStyle('bold');
          doc.setTextColor('#fff');

          // CONFIG IMAGEM BG (X,Y,W,H)
          doc.addImage(bgRelatorio, 20, 25, 556, 55);

          // CONFIG LOGO (X,Y,W,H)
          //doc.addImage(logo,50,36,50,30);

          // CONFIG TITULO 1
          doc.setFontSize(10);
          doc.text(titulo.toUpperCase(), pageWidth / 2, 45, 'center');

          // CONFIG TITULO 2
          doc.setFontSize(8);
          doc.text(titulo_grid_2.toUpperCase(), pageWidth / 2, 65, 'center');

          footer();
        },
        didParseCell: function (HookData) {
          HookData.cell.styles.fontSize = '6';

          if (campos.indexOf(HookData.row.raw.descricao) >= 0) {
            HookData.cell.styles.fontStyle = 'bold';
          }

          //Se for passado o parametro LINHATOTAL nas rows será aplicado as classes abaixo na linha
          if (HookData.row.raw.LINHATOTAL == true) {
            HookData.cell.styles.fontSize = '7';
            HookData.cell.styles.fontStyle = 'bold';
          }
        },
      });

      adicionarPage(
        rowsDespesas,
        doc,
        titulo_grid_1,
        titulo_arquivo,
        titulo_grid_2,
        columns,
        totalPage,
        rowsSaldos,
        columnsSaldos,
        user,
        pastor,
        tesoureiro,
        periodoRelatorio,
        totalRows,
        campos,
        bgRelatorio
      );
    }

    function adicionarPage(
      rowsDespesas,
      doc,
      titulo_grid_1,
      titulo_arquivo,
      titulo_grid_2,
      columns,
      totalPage,
      rowsSaldos,
      columnsSaldos,
      user,
      pastor,
      tesoureiro,
      periodoRelatorio,
      totalRows,
      campos,
      bgRelatorio
    ) {
      doc.addPage('p', 'pt', 'a4');

      var titulo = titulo_grid_1 + ' - DESPESAS';
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      //Paginação
      doc.page = totalPage + 1;
      function footer() {
        doc.setFontSize(8);
        doc.setTextColor('#000');

        doc.text(170, 830, `Usurário - ${user}`);
        doc.text(420, 830, moment().format('DD/MM/YYYY - HH:mm'));
        doc.text(570, 830, '' + doc.page);
        doc.page++;
      }

      function footer2() {
        doc.setFontSize(8);
        doc.setTextColor('#000');

        if (periodoRelatorio == 'M') {
          // ASSINATURAS
          doc.text('________________________________', 100, 800, 'center');
          doc.text(`${pastor}`, 100, 810, 'center');
          doc.text('Pastor(a)', 100, 820, 'center');

          doc.text('________________________________', 300, 800, 'center');
          doc.text(`${tesoureiro}`, 300, 810, 'center');
          doc.text('Tesoureiro(a)', 300, 820, 'center');

          doc.text('________________________________', 500, 800, 'center');
        }

        //doc.text(170, 830, `Usurário - ${user}`);
        //doc.text(420, 830, moment().format("DD/MM/YYYY - HH:mm"));
        doc.text(570, 830, '');
        //doc.page++;
      }

      //Grid
      var url = '/assets/images/logos/logo-branca-reduzida.png';
      var url_bg = '/assets/images/bg_relatorio.jpg';

      doc.autoTable(columns, rowsDespesas, {
        theme: 'striped',
        margin: { top: 90, left: 20, right: 20 },
        styles: { halign: 'left' },
        headStyles: {
          fillColor: [66, 66, 66],
        },
        didDrawPage: function (data) {
          // CONFIG TITULOS
          doc.setFontStyle('bold');
          doc.setTextColor('#fff');

          // CONFIG IMAGEM BG (X,Y,W,H)
          doc.addImage(bgRelatorio, 20, 25, 556, 55);

          // CONFIG LOGO (X,Y,W,H)
          //doc.addImage(logo,50,36,50,30);

          // CONFIG TITULO 1
          doc.setFontSize(10);
          doc.text(titulo.toUpperCase(), pageWidth / 2, 45, 'center');

          // CONFIG TITULO 2
          doc.setFontSize(8);
          doc.text(titulo_grid_2.toUpperCase(), pageWidth / 2, 65, 'center');

          footer();
        },
        didParseCell: function (HookData) {
          HookData.cell.styles.fontSize = '6';

          if (campos.indexOf(HookData.row.raw.descricao) >= 0) {
            HookData.cell.styles.fontStyle = 'bold';
          }

          //Se for passado o parametro LINHATOTAL nas rows será aplicado as classes abaixo na linha
          if (HookData.row.raw.LINHATOTAL == true) {
            HookData.cell.styles.fontSize = '7';
            HookData.cell.styles.fontStyle = 'bold';
          }
        },
      });

      if (periodoRelatorio == 'A' || periodoRelatorio == 'M') {
        addPageSaldos(
          doc,
          titulo_arquivo,
          rowsSaldos,
          columnsSaldos,
          titulo_grid_2,
          titulo_grid_1,
          user,
          totalRows,
          campos,
          bgRelatorio
        );
      } else {
        footer2();
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
      }
    }

    function addPageSaldos(
      doc,
      titulo_arquivo,
      rowsSaldos,
      columnsSaldos,
      titulo_grid_2,
      titulo_grid_1,
      user,
      totalRows,
      campos,
      bgRelatorio
    ) {
      doc.addPage('p', 'pt', 'a4');

      var titulo = titulo_grid_1 + ' - SALDOS';
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      //Paginação
      doc.page = 1;
      function footer() {
        doc.setFontSize(8);
        doc.setTextColor('#000');

        // ASSINATURAS
        doc.text('________________________________', 100, 800, 'center');
        doc.text(`${pastor}`, 100, 810, 'center');
        doc.text('Pastor(a)', 100, 820, 'center');

        doc.text('________________________________', 300, 800, 'center');
        doc.text(`${tesoureiro}`, 300, 810, 'center');
        doc.text('Tesoureiro(a)', 300, 820, 'center');

        doc.text('________________________________', 500, 800, 'center');

        doc.text(170, 840, `Usuário - ${user}`);
        doc.text(420, 840, moment().format('DD/MM/YYYY - HH:mm'));
        doc.text(570, 840, '' + doc.page);
        doc.page++;
      }

      //Grid
      var url = '/assets/images/logos/logo-branca-reduzida.png';
      var url_bg = '/assets/images/bg_relatorio.jpg';

      doc.autoTable(columnsSaldos, rowsSaldos, {
        theme: 'striped',
        margin: { top: 90, left: 20, right: 20 },
        styles: { halign: 'left' },
        headStyles: {
          fillColor: [66, 66, 66],
        },
        didDrawPage: function (data) {
          // CONFIG TITULOS
          doc.setFontStyle('bold');
          doc.setTextColor('#fff');

          // CONFIG IMAGEM BG (X,Y,W,H)
          doc.addImage(bgRelatorio, 20, 25, 556, 53);

          // CONFIG LOGO (X,Y,W,H)
          // doc.addImage(logo,50,36,50,30);

          // CONFIG TITULO 1
          doc.setFontSize(10);
          doc.text(titulo.toUpperCase(), pageWidth / 2, 45, 'center');

          // CONFIG TITULO 2
          doc.setFontSize(8);
          doc.text(titulo_grid_2.toUpperCase(), pageWidth / 2, 65, 'center');

          footer();
        },
        didParseCell: function (HookData) {
          HookData.cell.styles.fontSize = '6';

          if (campos.indexOf(HookData.row.raw.banco) >= 0) {
            HookData.cell.styles.fontStyle = 'bold';
          }
        },
      });

      //doc.save(`${titulo_arquivo}.pdf`);
      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    }
  }

  // ----------------- RELATÓRIO DE DIZIMOS E MOVIMENTAÇÕES
  exportAsPDF(
    titulo_arquivo: string,
    titulo_grid_1: string,
    titulo_grid_2: string,
    columns,
    rows,
    usuario,
    pastor?,
    tesoureiro?,
    totalRows?,
    strBanco?,
    tipoRelatorio?
  ) {
    let formRelatorio = titulo_arquivo;

    this.http.get('/assets/images/bg_relatorio.jpg', { responseType: 'blob' }).subscribe(data => {
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = function () {
        executa(reader.result);
      };
    });

    function executa(bgRelatorio) {
      var doc = new jsPDF('p', 'pt', 'a4');

      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      //Paginação
      doc.page = 1;
      function footer() {
        doc.setFontSize(8);
        doc.setTextColor('#000');

        doc.text(170, 840, `Usuário - ${usuario}`);
        doc.text(420, 840, moment().format('DD/MM/YYYY - HH:mm'));
        doc.text(570, 840, '' + doc.page);

        doc.page++;
      }

      function footer3() {
        doc.setFontSize(8);
        doc.setTextColor('#000');

        if (formRelatorio == 'Movimentações') {
          // ASSINATURAS
          doc.text('________________________________', 100, 800, 'center');
          doc.text(`${pastor}`, 100, 810, 'center');
          doc.text('Pastor(a)', 100, 820, 'center');

          doc.text('________________________________', 300, 800, 'center');
          doc.text(`${tesoureiro}`, 300, 810, 'center');
          doc.text('Tesoureiro(a)', 300, 820, 'center');

          doc.text('________________________________', 500, 800, 'center');
        }

        //doc.text(170, 830, `Usurário - ${user}`);
        //doc.text(420, 830, moment().format("DD/MM/YYYY - HH:mm"));
        doc.text(570, 830, '');
        //doc.page++;
      }
      
      doc.autoTable(columns, rows, {
        theme: 'striped',
        margin: { top: 90, left: 20, right: 20 },
        styles: { halign: 'left', fontSize: '6' },
        headStyles: {
          fillColor: [66, 66, 66],
        },
        didDrawPage: function (data) {
          // CONFIG TITULOS
          doc.setFontStyle('bold');
          doc.setTextColor('#fff');

          // CONFIG IMAGEM BG (X,Y,W,H)
          doc.addImage(bgRelatorio, 20, 25, 556, 53);

          // CONFIG LOGO (X,Y,W,H)
          // doc.addImage(logo,50,36,50,30);

          // CONFIG TITULO 1
          doc.setFontSize(10);
          doc.text(titulo_grid_1.toUpperCase(), pageWidth / 2, 45, 'center');

          // CONFIG TITULO 2
          doc.setFontSize(8);
          doc.text(titulo_grid_2.toUpperCase(), pageWidth / 2, 59, 'center');

          if (tipoRelatorio == 'movimentacoes') {
            // CONFIG TITULO 3 movimentaçoes
            doc.setFontSize(8);
            doc.text(strBanco.toUpperCase(), pageWidth / 2, 70, 'center');
          }

          footer();
        },
        didParseCell: function (HookData) {
          if (tipoRelatorio == 'resumo') {
            HookData.cell.styles.fontSize = '6';
          } else {
            HookData.cell.styles.fontSize = '6';
          }

          let raw;

          if (totalRows) {
            if (tipoRelatorio == 'movimentacoes') {
              if (
                HookData.row.index === totalRows ||
                HookData.row.index === totalRows + 1 ||
                HookData.row.index === totalRows + 2 ||
                HookData.row.index === totalRows + 3 ||
                HookData.row.index === totalRows + 4
              ) {
                HookData.cell.styles.fontStyle = 'bold';
              }
            } else {
              if (HookData.row.index === totalRows) {
                HookData.cell.styles.fontStyle = 'bold';
              }
            }
          }

          if (tipoRelatorio == 'remessas') {
            let campos = ['Total cota Orçamentária(apurado no mês)', 'Total cota Orçamentária(apurado)'];

            if (campos.indexOf(HookData.row.raw.desc) >= 0) {
              HookData.cell.styles.fontStyle = 'bold';
            }
          }
        },
      });

      if (tipoRelatorio == 'movimentacoes') {
        footer3();
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
      } else {
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
      }
    }
  }

  exportGeral(titulo_grid_1, titulo_grid_2, usuario, columns, rows) {
    this.http.get('/assets/images/bg_relatorio.jpg', { responseType: 'blob' }).subscribe(data => {
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = function () {
        executa(reader.result);
      };
    });

    var doc = new jsPDF('p', 'pt', 'a4');

    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    function executa(bgRelatorio) {
      var doc = new jsPDF('p', 'pt', 'a4');

      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      //Paginação
      doc.page = 1;
      function footer() {
        doc.setFontSize(8);
        doc.setTextColor('#000');
        doc.text(170, 840, `Usuário - ${usuario}`);
        doc.text(420, 840, moment().format('DD/MM/YYYY - HH:mm'));
        doc.text(570, 840, '' + doc.page);
        doc.page++;
      }

      doc.autoTable(columns, rows, {
        theme: 'striped',
        margin: { top: 90, left: 20, right: 20 },
        styles: { halign: 'left', fontSize: '6' },
        headStyles: {
          fillColor: [66, 66, 66],
        },
        didDrawPage: function (data) {
          // CONFIG TITULOS
          doc.setFontStyle('bold');
          doc.setTextColor('#fff');

          // CONFIG IMAGEM BG (X,Y,W,H)
          doc.addImage(bgRelatorio, 20, 25, 556, 53);

          // CONFIG LOGO (X,Y,W,H)
          // doc.addImage(logo,50,36,50,30);

          // CONFIG TITULO 1
          doc.setFontSize(10);
          doc.text(titulo_grid_1.toUpperCase(), pageWidth / 2, 45, 'center');

          // CONFIG TITULO 2
          doc.setFontSize(8);
          doc.text(titulo_grid_2.toUpperCase(), pageWidth / 2, 65, 'center');

          footer();
        },
        didParseCell: function (HookData) {
          //Se for passado o parametro TITLELIST nas rows será aplicado as classes abaixo na linha
          if (HookData.row.raw.TITLELIST) {
            HookData.cell.styles.fontSize = '9';
            HookData.cell.styles.fontStyle = 'bold';
          }

          //Se for passado o parametro LINHATOTAL nas rows será aplicado as classes abaixo na linha
          if (HookData.row.raw.LINHATOTAL) {
            HookData.cell.styles.fontSize = '7';
            HookData.cell.styles.fillColor = '#e0e0e0';
            HookData.cell.styles.fontStyle = 'bold';
          }
        },
      });

      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    }
  }

  criarArquivoTxt(nome, conteudo) {
    let nomeArquivo = nome;
    const fileType = this._FileSaverService.genType(nomeArquivo);
    const txtBlob = new Blob([conteudo], { type: fileType });
    this._FileSaverService.save(txtBlob, nomeArquivo);
  }

  //EXPORTE DA TABELA REFERENTE A PAGINA ORÇADO X REALIZADO

  orcadoXrealizado(titulo_grid_1, titulo_grid_2, columns, rows) {
    this.http.get('/assets/images/bg_relatorio.jpg', { responseType: 'blob' }).subscribe(data => {
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = function () {
        executa(reader.result);
      };
    });

    var doc = new jsPDF('p', 'pt', 'a4');

    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    function executa(bgRelatorio) {
      var doc = new jsPDF('p', 'pt', 'a4');

      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      //Paginação
      doc.page = 1;
      function footer() {
        doc.setFontSize(8);
        doc.setTextColor('#000');
        doc.text(420, 840, moment().format('DD/MM/YYYY - HH:mm'));
        doc.text(570, 840, '' + doc.page);
        doc.page++;
      }

      doc.autoTable(columns, rows, {
        theme: 'striped',
        margin: { top: 90, left: 20, right: 20 },
        styles: { halign: 'left', fontSize: '6' },
        headStyles: {
          fillColor: [66, 66, 66],
        },
        didDrawPage: function (data) {
          // CONFIG TITULOS
          doc.setFontStyle('bold');
          doc.setTextColor('#fff');

          // CONFIG IMAGEM BG (X,Y,W,H)
          doc.addImage(bgRelatorio, 20, 25, 556, 53);

          // CONFIG LOGO (X,Y,W,H)
          // doc.addImage(logo,50,36,50,30);

          // CONFIG TITULO 1
          doc.setFontSize(10);
          doc.text(titulo_grid_1.toUpperCase(), pageWidth / 2, 45, 'center');

          // CONFIG TITULO 2
          doc.setFontSize(8);
          doc.text(titulo_grid_2.toUpperCase(), pageWidth / 2, 65, 'center');
        },
        didParseCell: function (HookData) {
          //Se for passado o parametro TITLELIST nas rows será aplicado as classes abaixo na linha
          if (HookData.row.raw.TITLELIST) {
            HookData.cell.styles.fontSize = '9';
            HookData.cell.styles.fontStyle = 'bold';
          }

          //Se for passado o parametro LINHATOTAL nas rows será aplicado as classes abaixo na linha
          if (HookData.row.raw.LINHATOTAL) {
            HookData.cell.styles.fontSize = '7';
            HookData.cell.styles.fillColor = '#e0e0e0';
            HookData.cell.styles.fontStyle = 'bold';
          }
        },
      });

      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    }
  }
}
